import { DynamicImage, Text } from 'components';
import React, { FC } from 'react';
import styled from 'styled-components';
import { tablet } from 'styles/breakpoints';

interface CardListProps {
  title: string;
  bgColor?: string;
  cardColor?: string;
  imgUrlList: { label: string; imgUrl: string; text: string }[];
}

const Container = styled.div<{ bgColor?: string }>`
  background-color: ${({ bgColor }) => (bgColor ? bgColor : '#FAF1EB')};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 4rem 1rem;
  @media ${tablet} {
    padding: 2.25rem 1rem;
  }
`;

const InnerContainer = styled.div`
  width: 100%;
  max-width: 71.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled(Text)`
  color: #1c1c28;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 130%;
  span {
    color: #05a56f;
  }
`;

const ItemLabel = styled(Text)`
  font-size: 0.75rem;
  font-weight: 700;
  line-height: normal;
  line-height: 140%;
  text-align: center;
  margin-top: 8px;
`;

const GridContainer = styled.div<{ elementCount: number }>`
  display: grid;
  grid-template-columns: ${({ elementCount }) =>
    `repeat(${elementCount / 2}, 1fr)`};
  column-gap: 0.75rem;
  row-gap: 0.75rem;
  justify-items: center;
  max-width: 43.3rem;
  margin-top: 2rem;
  @media ${tablet} {
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    margin-top: 1.5rem;
  }
`;

const Card = styled.div<{ cardColor?: string }>`
  background-color: ${({ cardColor }) => (cardColor ? cardColor : '#cdebf6')};
  padding: 0.5rem 1.5rem;
  border-radius: 16px;
  width: 10.25rem;
  height: 11.75rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media ${tablet} {
    width: 100%;
  }
`;

const Image = styled(DynamicImage)`
  width: 5.75rem;
  height: 4.375rem;
`;

const TextStyled = styled(Text)`
  color: #4d4d4d;
  text-align: center;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 0.25rem;
`;

const CardListWithSubtitles: FC<CardListProps> = ({
  title,
  imgUrlList,
  bgColor,
  cardColor,
}) => {
  const elementCount = imgUrlList.length;
  const renderCards = () =>
    imgUrlList.map(({ label, imgUrl, text }, index) => (
      <Card key={index} cardColor={cardColor}>
        <Image src={imgUrl} alt="" />
        <ItemLabel>{label}</ItemLabel>
        <TextStyled>{text}</TextStyled>
      </Card>
    ));
  return (
    <Container bgColor={bgColor}>
      <InnerContainer>
        <Title dangerouslySetInnerHTML={{ __html: title }} />
        <GridContainer elementCount={elementCount}>
          {renderCards()}
        </GridContainer>
      </InnerContainer>
    </Container>
  );
};

export default CardListWithSubtitles;
